import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wordspacing'
})
export class WordspacingPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if(value) {
      if(value == "ACH" ) {
        return value;
      } else if(value =="PLGManager") {
        return "PLG Manager"
      }
       else {
        return value.replace(/([A-Z])/g, ' $1').trim()
      } 
    }
  }

}
