import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { TruncatePipe } from './truncate.pipe';
import { HighlightPipe } from './highlightText.pipe';
import { WordspacingPipe } from './wordspacing.pipe';

@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        TruncatePipe,
        HighlightPipe,
        WordspacingPipe
    ],
    imports     : [],
    exports     : [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        TruncatePipe,
        HighlightPipe,
        WordspacingPipe
    ]
})
export class FusePipesModule
{
}
