import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
            {
                id       : 'ViewDashboard',
                title    : 'Dashboard', 
                type     : 'item',
                icon     : 'apps',
                url      : '/app/dashboard',
            },
            {
                id       : 'ViewPCIToolKit',
                title    : 'PCI Toolkit',
                type     : 'item',
                icon     : 'pie_chart',
                url      : '/app/pci-toolkit',
            },
            {
                id       : 'ViewPrecommToolKit',
                title    : 'PreComm Toolkit',
                type     : 'item',
                icon     : 'bar_chart',
                url      : '/app/precomm-toolkit',
            },
            {
                id       : 'ViewInConRadar',
                title    : 'InConRadar Standalone',
                type     : 'item',
                icon     : 'perm_scan_wifi',
                url      : '/app/in-con-redar'
            },
            {
                id       : 'ViewReports',
                title    : 'Reports',
                type     : 'item',
                icon     : 'insert_drive_file',
                url      : '/app/reports',
            }, 
            {
                id       : 'ViewISOs',
                title    : 'ISOs',
                type     : 'item',
                icon     : 'device_hub',
                url      : '/app/reports',
            },
            {
                id       : 'ViewMerchants',
                title    : 'Merchants',
                type     : 'item',
                icon     : 'store',
                url      : '/app/reports',
            },
            {
                id       : 'ViewUserManagement',
                title    : 'User Management',
                type     : 'item',
                icon     : 'supervised_user_circle',
                url      : '/app/usermanagement',
            }, 
            {
                id       : 'ViewSupport',
                title    : 'Support',
                type     : 'item',
                icon     : 'contact_support',
                url      : '/app/support',
            },
            {
                id       : 'ViewSettings',
                title    : 'Settings',
                type     : 'item',
                icon     : 'settings',
                url      : '/app/settings',
            },
];
