import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({providedIn: 'root'})
export class UserConfigService {

    public loggedInUser:any = {};
    private userMode = new BehaviorSubject<any>(null);
    public userModeChange = this.userMode.asObservable();
    private permissions = new BehaviorSubject<any>(null);
    public  permissionsChange = this.permissions.asObservable(); 
    private userHierarchy = new BehaviorSubject<any>(null);
    public getUserHierarchy = this.userHierarchy.asObservable();
    private toggle = new BehaviorSubject<any>(false);
    public changeToggle = this.toggle.asObservable();
    selectedGrp: any;
    roles: any;
    listingActions: any  = [];
    actions: any = [];
    constructor() { }
    public setHierarchy(data) {
        this.userHierarchy.next(data);
    }
    public setUserMode(obj: object) {
        this.userMode.next(obj);
    }
    public getUserMode() {
        return this.userMode.getValue();
    }
    public setPermissions(obj: object) {
        this.permissions.next(obj);
    }
    public getPermissions() {
        return this.permissions.getValue();
    }
    public getToogle() {
        return this.toggle.getValue();
    }
    public setToogle(obj) {
        this.toggle.next(obj);
    }
    public getlistingActions(obj) { 
        if (obj) {
            this.listingActions = [];
            this.roles = this.permissions.getValue();
            this.selectedGrp =  this.roles.filter(x => x.ModuleName == obj.ModuleName);
            if(this.selectedGrp.length > 0) {
               this.actions = obj.ActionName.split(",");
               if(this.actions.length > 0) {
                this.actions.forEach(element => {
                    if(element && this.selectedGrp[0].ActionName.includes(`${element}`)) {
                        this.listingActions.push(element)
                    }
                }); 
               } else {
                   return this.listingActions = []
               } 
            } else {
                this.listingActions = [];
            } 
         }
           return this.listingActions
    }
}