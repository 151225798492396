import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@fuse/services/auth.guard";

const routes: Routes = [ 
    {
        path: "app",
        loadChildren: () =>
            import("./main/pages/pages.module").then((m) => m.PagesModule),
        // canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
    },
    {
        path: "",
        loadChildren: () =>
            import("./main/authentication/authentication.module").then(
                (m) => m.AuthenticationModule
            ),
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
