import {
    AfterViewInit,
    ChangeDetectorRef,
    Directive,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
} from "@angular/core";
import { UserConfigService } from "@fuse/services/user.config.service";

@Directive({
    selector: "[appPermissions]",
})
export class PermissionsDirective implements OnChanges, OnInit {
    public element;
    @Input() appPermissions: any;
    @Input() Actions;
  roles: any;
  selectedGrp: any;

    constructor( 
        private _templateRef : TemplateRef<any>,
        private _viewContainer: ViewContainerRef,
        private _userConfig: UserConfigService
    ) { }

    ngOnInit(): void {}
    ngOnChanges() {
        if (this.appPermissions) {
            this._userConfig.permissionsChange.subscribe((res: any) => {
                if (res && res.length > 0) {
                    this.roles = res;
                    if (this.appPermissions) { 
                     this.selectedGrp =  this.roles.filter(x => x == this.appPermissions);
                     if(this.selectedGrp.length > 0) {
                      this._viewContainer.clear();
                      this._viewContainer.createEmbeddedView(this._templateRef); 
                     }
                      else {
                        this._viewContainer.clear();
                      } 
                     }
                    }
                });
        }
      }
    }
