
<!-- PROGRESS BAR -->
<div style="position: relative">
    <!-- the position of the parent container must be set to relative -->
    <!-- It is really important to set loaderId for non-master loader -->
    <ngx-ui-loader [loaderId]="'loader-01'"></ngx-ui-loader>
  </div>
  <ngx-ui-loader></ngx-ui-loader>
<fuse-progress-bar></fuse-progress-bar>
<router-outlet></router-outlet>
<!-- / PROGRESS BAR -->
